import React from 'react';

const availableDatePicker = (props) => {
  return (
  <div>
      <span>Date Picker thingy</span>
  </div>
  );
};

export default availableDatePicker;
